import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../DROPDOWNDATA";
import "./Viewenabler.css";
import { useSelector } from "react-redux";
import Count from "./Count";
import { Modal, QRCode } from "antd";

const Viewenabler = ({ setBreadcrumItem }) => {
  const Navigate = useNavigate();
  const UserRole = useSelector((store) => store.privilege);
  const [EnablerDetails, setEnablerDetails] = useState();
  const [PayModal, setPayModal] = useState(false)
  const { id } = useParams();

  async function View_enabler_details() {
    let data = {
      enabler_id: id,
    };
    await fetch(URL + "/manage_enabler_view_enabler_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setEnablerDetails(obj.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const Names = ({ heading, value }) => {
    return (
      <div className="my-2 flex font-medium text-[18px]">
        <p className="w-[160px] min-w-[160px]">{heading}</p>
        <p className="mx-2">:</p>
        <p className="break-all">{value}</p>
      </div>
    );
  };
  useEffect(() => {
    setBreadcrumItem([{ name: "", link: "" }]);
    View_enabler_details();
  }, []);

  return (
    <div className="view_enabler_container">
      {/* QR MODAL */}
      <Modal title="UPI QR-code" footer={false} centered open={PayModal}  onCancel={()=> setPayModal(false)}>
      <QRCode className="mx-auto" value={`upi://pay?pa=${EnablerDetails?.upi_id}&pn=${EnablerDetails?.users_firstname}`} size={350}  />
      </Modal>
      <div className="view_enabler_heading_container">
        <button
          className="text-[18px] font-semibold"
          onClick={() => Navigate(-1)}
        >
          <i class="fa-solid fa-chevron-left"></i> Back
        </button>

        <div className="view_enabler_btn_container">
          {EnablerDetails?.upi_id&&<button onClick={()=>setPayModal(true)}>Pay</button>}
          <NavLink style={{ pointerEvents: "none", opacity: 0.4 }} to={"/"}>
            Book slots
          </NavLink>
          <NavLink to={`/addenabler/${id}`}>
            <i className="fa-solid fa-pen"></i> Edit profile
          </NavLink>
        </div>
      </div>

      {/* PROFILE DATA LIST */}
      {/* <div className="view_enabler_details_container">
        <div className="view_enabler_general_container">
          <div className="view_enabler_general_details">
            <div className="view_enabler_profile_img">
              <img
                src={
                  EnablerDetails?.profile_img == null ||
                  EnablerDetails?.profile_img == ""
                    ? "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                    : EnablerDetails?.profile_img
                }
                alt="enabler profile img"
              />
            </div>

            <div className="view_enabler_profile_details">
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">Qualification :</p>
                <p>{EnablerDetails?.qualification}</p>
              </div>
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">DOB :</p>
                <p>{EnablerDetails?.dob}</p>
              </div>
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">Mobile no :</p>
                <p>{EnablerDetails?.users_mobile}</p>
              </div>
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">Email :</p>
                <p>{EnablerDetails?.users_email}</p>
              </div>
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">Experience :</p>
                <p>{EnablerDetails?.experience}</p>
              </div>

              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">Specialisation :</p>
                <p>{EnablerDetails?.specialisation}</p>
              </div>
              <div className="view_enabler_profile_details_list">
                <p className="profile_heading">RCI no :</p>
                <p>{EnablerDetails?.rci_no}</p>
              </div>

              <table className="language_table">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Speak</th>
                  </tr>
                </thead>
                <tbody>
                  {EnablerDetails?.enabler_languages?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.lang}</td>
                        <td>{item?.read}</td>
                        <td>{item?.write}</td>
                        <td>{item?.speak}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="view_enabler_about_time_container">
          <div className="view_enabler_about_container">
            <h2>About enabler</h2>

            <div className="view_enabler_award_hobbies_container">
              <div className="view_enabler_award_container">
                <h3>
                  <b>Awards/Recognition/Certification</b>
                </h3>
                <div>
                  {EnablerDetails?.awards_certificates
                    ?.split(",")
                    ?.map((item, index) => {
                      if (item != "") {
                        return (
                          <p key={index}>
                            {index + 1}. {item}
                          </p>
                        );
                      }
                    })}
                </div>
                <h3>
                  <b>Passion</b>
                </h3>
                <div>
                  {EnablerDetails?.life_passion
                    ?.split(",")
                    ?.map((item, index) => {
                      if (item != "") {
                        return (
                          <p key={index}>
                            {index + 1}. {item}
                          </p>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="view_enabler_hoobies_container">
                <h3>
                  <b>Favorite thing in career?</b>
                </h3>
                <p>{EnablerDetails?.fav_thing_in_career}</p>
                <h3>
                  <b>Proudest accomplishment</b>
                </h3>
                <p>{EnablerDetails?.proudest_accomplishment}</p>
                <h3>
                  <b>Hobbies</b>
                </h3>
                <div>
                  {EnablerDetails?.hobbies?.split(",")?.map((item, index) => {
                    if (item != "") {
                      return (
                        <p key={index}>
                          {index + 1}. {item}
                        </p>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="view_enabler_time_container">
            <h2>Available time slots</h2>

            <div className="time_slots_view_container">
              {EnablerDetails?.enabler_slot_timings?.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>

          <div className="view_enabler_signature_rci_view">
            <h2>SIgnature and RCI</h2>

            <div className="view_enabler_image_view_container">
              <div className="enabler_signature_block">
                {EnablerDetails?.user_signature && (
                  <div className="wrapper_img">
                    <img src={EnablerDetails?.user_signature} alt="" />
                  </div>
                )}
                <p>Signature</p>
              </div>
              <div className="enabler_RCI_block">
                {EnablerDetails?.rci_doc && (
                  <>
                    {EnablerDetails?.rci_doc?.split(".").pop() == "pdf" ? (
                      <a
                        href={EnablerDetails?.rci_doc}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View PDF
                      </a>
                    ) : (
                      <div className="wrapper_img">
                        <img src={EnablerDetails?.rci_doc} alt="" />
                      </div>
                    )}
                  </>
                )}

                <p>RCI</p>
              </div>
            </div>
          </div>
          <div className="view_enabler_total_count items-stretch">
            {(EnablerDetails?.enabler_role == "enabler" ||
              EnablerDetails?.enabler_role == "School admin" ||
              EnablerDetails?.enabler_role == "Assessor - screening" ||
              EnablerDetails?.enabler_role == "master") && (
              <Count
                count={EnablerDetails?.assigned_school}
                path={`manageschool/${btoa(id)}`}
                title={"School"}
              />
            )}
            {(EnablerDetails?.enabler_role == "enabler" ||
              EnablerDetails?.enabler_role == "master") && (
              <Count
                count={EnablerDetails?.individual_schedule_count}
                path={`scheduleList/0/${btoa(id)}/1`}
                title={"Intervention Student"}
              />
            )}
            {(EnablerDetails?.enabler_role == "enabler" ||
              EnablerDetails?.enabler_role == "master") && (
              <Count
                count={EnablerDetails?.group_schedule_count}
                path={`scheduleList/2/${btoa(id)}/1`}
                title={"Group Intervention Student"}
              />
            )}
            {EnablerDetails?.enabler_role == "Assessor - screening" && (
              <Count
                count={EnablerDetails?.screening_completed}
                path={``}
                title={"No.of Screening completed"}
              />
            )}
            {EnablerDetails?.enabler_role == "Approver - screening" && (
              <Count
                count={EnablerDetails?.approved_reports}
                path={``}
                title={"No.of Screening Approved"}
              />
            )}
            {EnablerDetails?.enabler_role == "master" && (
              <Count
                count={EnablerDetails?.enabler_count}
                path={``}
                title={"No.of Enabler"}
              />
            )}
          </div>
        </div>
      </div> */}
      <div className="bg-[#FFDAC1]">
        <div className="bg-[#FF9AA2] w-full h-[30px]"></div>
        <div className="p-4 flex items-start w-full">
          <div className="left-side w-[30%]">
            <div className="bg-[#FFFFFF] p-2 rounded-md">
              <div className="flex items-end justify-start  ">
                <img
                  className="w-[150px] bg-[#ccc] rounded-md overflow-hidden"
                  src={
                    EnablerDetails?.profile_img == null ||
                    EnablerDetails?.profile_img == ""
                      ? "https://static.thenounproject.com/png/4035887-200.png"
                      : EnablerDetails?.profile_img
                  }
                  alt="profile img"
                />
                <p className="ml-2 text-[22px] font-semibold">
                  {EnablerDetails?.users_firstname}
                </p>
              </div>

              <Names
                heading={"Date of birth"}
                value={EnablerDetails?.dob?.split("-")?.reverse()?.join("-")}
              />
              <Names
                heading={"Qualification"}
                value={EnablerDetails?.qualification}
              />
              <Names heading={"Email"} value={EnablerDetails?.users_email} />
              <Names
                heading={"Mobile no"}
                value={EnablerDetails?.users_mobile}
              />
              <Names
                heading={"Experience"}
                value={EnablerDetails?.experience}
              />
              <Names
                heading={"Specialisation"}
                value={EnablerDetails?.specialisation}
              />
              <Names heading={"RCI no"} value={EnablerDetails?.rci_no} />
            </div>
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th className="py-3">Language</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th>Speak</th>
                </tr>
              </thead>
              <tbody>
                {EnablerDetails?.enabler_languages?.map((item, index) => {
                  return (
                    <tr key={index} className="border-b-2 border-[#000]">
                      <td className="py-1">{item?.lang}</td>
                      <td>{item?.read}</td>
                      <td>{item?.write}</td>
                      <td>{item?.speak}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="right-side w-[68%] ml-[2%]">
            <div className="flex items-start w-full">
              <div className="bg-[#F3FCF9] p-2 rounded-md w-[50%]">
                <p className="text-[22px] mb-2">Available time slots</p>
                <div className="flex flex-wrap items-start border-b-2 pb-3">
                  {EnablerDetails?.enabler_slot_timings?.map((item, index) => {
                    return (
                      <p
                        className="py-1 m-1 text-[14px] px-3 font-medium rounded-xl bg-[#D9D9D9]"
                        key={index}
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
                <p className="text-[22px] mb-2">Signature and RCI</p>
                <div className="flex">
                  <div className="enabler_signature_block">
                    {EnablerDetails?.user_signature && (
                      <div className="">
                        <img
                          className="w-[200px]"
                          src={EnablerDetails?.user_signature}
                          alt=""
                        />
                      </div>
                    )}
                    {EnablerDetails?.user_signature && (
                      <p className="text-center font-semibold">Signature</p>
                    )}
                  </div>
                  <div className="enabler_RCI_block">
                    {EnablerDetails?.rci_doc && (
                      <>
                        {EnablerDetails?.rci_doc?.split(".").pop() == "pdf" ? (
                          <a
                            href={EnablerDetails?.rci_doc}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View PDF
                          </a>
                        ) : (
                          <div className="wrapper_img">
                            <img
                              className="w-[200px]"
                              src={EnablerDetails?.rci_doc}
                              alt=""
                            />
                          </div>
                        )}
                      </>
                    )}

                    {EnablerDetails?.rci_doc && (
                      <p className="text-center font-semibold">RCI</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-[48%] ml-[2%]">
                <p className="text-[22px] text-[#007872] mb-4 font-semibold">
                  About Enabler
                </p>
                <h3 className="text-[20px] font-medium">
                  Awards / Recognition / Certification
                </h3>
                <div className="border-b-2 pb-2 border-[#000] mb-1">
                  {EnablerDetails?.awards_certificates
                    ?.split(",")
                    ?.map((item, index) => {
                      if (item != "") {
                        return (
                          <p key={index}>
                            {index + 1}. {item}
                          </p>
                        );
                      }
                    })}
                </div>

                <h3 className="text-[20px] font-medium">Hobbies</h3>
                <div className="border-b-2 pb-2 border-[#000] mb-1">
                  {EnablerDetails?.hobbies?.split(",")?.map((item, index) => {
                    if (item != "") {
                      return (
                        <p key={index}>
                          {index + 1}. {item}
                        </p>
                      );
                    }
                  })}
                </div>
                <h3 className="text-[20px] font-medium">Passion</h3>
                <div className="border-b-2 pb-2 border-[#000] mb-1">
                  {EnablerDetails?.life_passion
                    ?.split(",")
                    ?.map((item, index) => {
                      if (item != "") {
                        return (
                          <p key={index}>
                            {index + 1}. {item}
                          </p>
                        );
                      }
                    })}
                </div>

                <h3 className="text-[20px] font-medium">
                  Favourite thing in Career ?
                </h3>
                <div className="border-b-2 pb-2 border-[#000] mb-1">
                  <p>{EnablerDetails?.fav_thing_in_career}</p>
                </div>
                <h3 className="text-[20px] font-medium">
                  Proudest accomplishment
                </h3>
                <div className="border-b-2 pb-2 border-[#000] mb-1">
                  <p>{EnablerDetails?.proudest_accomplishment}</p>
                </div>
              </div>
            </div>
            <div className="view_enabler_total_count items-stretch bg-[#F3FCF9]">
              {(EnablerDetails?.enabler_role == "enabler" ||
                EnablerDetails?.enabler_role == "School admin" ||
                EnablerDetails?.enabler_role == "Assessor - screening" ||
                EnablerDetails?.enabler_role == "master") && (
                <Count
                  count={EnablerDetails?.assigned_school}
                  path={`manageschool/${btoa(id)}`}
                  title={"School"}
                />
              )}
              {(EnablerDetails?.enabler_role == "enabler" ||
                EnablerDetails?.enabler_role == "master") && (
                <Count
                  count={EnablerDetails?.individual_schedule_count}
                  path={`scheduleList/0/${btoa(id)}/1`}
                  title={"Intervention Student"}
                />
              )}
              {(EnablerDetails?.enabler_role == "enabler" ||
                EnablerDetails?.enabler_role == "master") && (
                <Count
                  count={EnablerDetails?.group_schedule_count}
                  path={`scheduleList/2/${btoa(id)}/1`}
                  title={"Group Intervention Student"}
                />
              )}
              {(EnablerDetails?.enabler_role == "enabler" ||
                EnablerDetails?.enabler_role == "master") && (
                <Count
                  count={EnablerDetails?.total_completed_classes}
                  path={``}
                  title={"Enabler completed classes"}
                />
              )}
              {EnablerDetails?.enabler_role == "Assessor - screening" && (
                <Count
                  count={EnablerDetails?.screening_completed}
                  path={``}
                  title={"No.of Screening completed"}
                />
              )}
              {EnablerDetails?.enabler_role == "Approver - screening" && (
                <Count
                  count={EnablerDetails?.approved_reports}
                  path={``}
                  title={"No.of Screening Approved"}
                />
              )}
              {EnablerDetails?.enabler_role == "master" && (
                <Count
                  count={EnablerDetails?.enabler_count}
                  path={``}
                  title={"No.of Enabler"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewenabler;
