import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        precision:0
    }
    },
  },
};

const Stackedbar = ({ d1, d2, d3 }) => {
  const labels = [`Games (${d3[0]})`, `LWS (${d3[1]})`, `PWS (${d3[2]})`];

  const data = {
    labels,
    datasets: [
      {
        label: "Incompleted",
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data: d1,
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Completed",
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data: d2,
        backgroundColor: "rgb(75, 192, 192)",
      },
      // {
      //   label: "Total",
      //   // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      //   data: d3,
      //   backgroundColor: "rgb(53, 162, 235)",
      // },
    ],
  };
  return <Bar options={options} data={data} style={{ zoom: "124%" }} />;
};

export default Stackedbar;
