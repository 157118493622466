import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
const Generealdetails = ({
  ActiveStep,
  GeneralDetails_Data,
  setGeneralDetails_Data,
  LanguageData,
  setLanguageData,
  RemoveLanguage,
  ProfileImage,
  setProfileImage,
  SignatureImage,
  setSignatureImage,
  ADD_Enabler,
}) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <div
      className={
        ActiveStep == 1
          ? "general_details_container "
          : "general_details_container active"
      }
    >
      <div className="section_wrapper">
        <div className="section section1">
          <div className="section_input_wrapper">
            <label htmlFor="">Enter name</label>
            <input
              type="text"
              maxLength={80}
              placeholder="Enter name"
              // defaultValue={GeneralDetails_Data?.enabler_name}
              value={GeneralDetails_Data?.enabler_name}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  enabler_name: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
                });
              }}
            />
          </div>
          <div className="section_input_wrapper">
            <label htmlFor="">Enter mobile no</label>
            <input
              maxLength={10}
              type="text"
              placeholder="Enter mobile no"
              value={GeneralDetails_Data?.enabler_mobile_number}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  enabler_mobile_number: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
          </div>
          <div className="section_input_wrapper">
            <label htmlFor="">Enter email id</label>
            <input
              type="text"
              maxLength={80}
              placeholder="Enter email id"
              value={GeneralDetails_Data?.email}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  email: e.target.value.replace(/[^a-zA-Z\s0-9@._]/g, ""),
                });
              }}
            />
          </div>

          <div className="section_input_wrapper">
            <label htmlFor="">Date of birth</label>
            {/* <input
              type="date"
              name=""
              id=""
              max={new Date().toISOString().substring(0, 10)}
              className="dob"
              value={GeneralDetails_Data?.dob}
              onChange={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  dob: e.target.value,
                });
              }}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture={true}
                label={"DOB"}
                value={GeneralDetails_Data?.dob}
                inputFormat={"dd/MM/yyyy"}
                // onError={ErrorHandler}
                onChange={(e) => {
                  setGeneralDetails_Data({
                    ...GeneralDetails_Data,
                    dob: formatDate(e),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    helperText={params?.inputProps?.placeholder}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="section_input_wrapper">
            <label htmlFor="">Enter specialisation</label>
            {/* <input
                    type="text"
                    maxLength={30}
                    placeholder="Enter email id"
                    value={GeneralDetails_Data?.email}
                    onInput={(e) => {
                      setGeneralDetails_Data({
                        ...GeneralDetails_Data,
                        email: e.target.value.replace(/[^a-zA-Z\s0-9@.]/g, ""),
                      });
                    }}
                  /> */}
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Enter specialisation"
              value={GeneralDetails_Data?.specialisation}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  specialisation: e.target.value.replace(
                    /[^a-zA-Z0-9\s-,.'"()/]/g,
                    ""
                  ),
                });
              }}
            ></textarea>
          </div>
        </div>
        <div className="section section2">
          <div className="section_input_wrapper">
            <label htmlFor="">Enter qualification</label>
            <input
              type="text"
              placeholder="Enter qualification"
              value={GeneralDetails_Data?.qualification}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  qualification: e.target.value.replace(
                    /[^a-zA-Z\s0-9-,.]/g,
                    ""
                  ),
                });
              }}
            />
          </div>
          <div className="section_input_wrapper">
            <label htmlFor="">Enter UPI id</label>
            <input
              type="text"
              placeholder="Enter UPI id"
              value={GeneralDetails_Data?.upi_id}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  upi_id: e.target.value.replace(
                    /[^a-zA-Z\s0-9-@.]/g,
                    ""
                  ),
                });
              }}
            />
          </div>
          <div className="section_input_wrapper">
            <label htmlFor="">Enter languages known</label>
            {/* <input
                    type="text"
                    placeholder="Enter languages known"
                    value={GeneralDetails_Data?.languages_known}
                    onInput={(e) => {
                      setGeneralDetails_Data({
                        ...GeneralDetails_Data,
                        languages_known: e.target.value.replace(
                          /[^a-zA-Z\s-,]/g,
                          ""
                        ),
                      });
                    }}
                  /> */}

            <div className="general_details_language_container">
              {LanguageData?.map((item, index) => {
                return (
                  <div className="language_input_dropdown" key={index}>
                    <div>
                      <label htmlFor="">Language</label>

                      <input
                        type="text"
                        placeholder="Enter language"
                        value={item.lang}
                        onInput={(e) => {
                          let newArray = [...LanguageData];
                          newArray[index] = {
                            ...newArray[index],
                            lang: e.target.value
                              .replace(/[^a-zA-Z-\s]/g, "")
                              .toUpperCase(),
                          };

                          setLanguageData(newArray);
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="">Read</label>
                      <select
                        name=""
                        id=""
                        value={item.read}
                        defaultValue={item.read}
                        onChange={(e) => {
                          let newArray = [...LanguageData];
                          newArray[index] = {
                            ...newArray[index],
                            read: e.target.value,
                          };

                          setLanguageData(newArray);
                        }}
                      >
                        <option value="">Select level</option>
                        <option value="120">No proficiency</option>
                        <option value="121">Beginner</option>
                        <option value="122">Intermediate</option>
                        <option value="123"> proficiency</option>
                        <option value="124"> Expert</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="">Write</label>
                      <select
                        name=""
                        id=""
                        defaultValue={item.write}
                        value={item.write}
                        onChange={(e) => {
                          let newArray = [...LanguageData];
                          newArray[index] = {
                            ...newArray[index],
                            write: e.target.value,
                          };

                          setLanguageData(newArray);
                        }}
                      >
                        <option value="">Select level</option>
                        <option value="120">No proficiency</option>
                        <option value="121">Beginner</option>
                        <option value="122">Intermediate</option>
                        <option value="123"> proficiency</option>
                        <option value="124"> Expert</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="">Speak</label>
                      <select
                        name=""
                        id=""
                        defaultValue={item.speak}
                        value={item.speak}
                        onChange={(e) => {
                          let newArray = [...LanguageData];
                          newArray[index] = {
                            ...newArray[index],
                            speak: e.target.value,
                          };

                          setLanguageData(newArray);
                        }}
                      >
                        <option value="">Select level</option>
                        <option value="120">No proficiency</option>
                        <option value="121">Beginner</option>
                        <option value="122">Intermediate</option>
                        <option value="123"> proficiency</option>
                        <option value="124"> Expert</option>
                      </select>
                    </div>
                    {index != 0 && (
                      <button
                        title="Remove"
                        onClick={() => {
                          RemoveLanguage(index);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="general_details_language_add_button">
              <button
                onClick={() => {
                  setLanguageData((prev) => {
                    return [
                      ...prev,
                      { lang: "", read: "", speak: "", write: "" },
                    ];
                  });
                }}
              >
                <i className="fa-solid fa-circle-plus"></i> Add new
              </button>
            </div>
          </div>

          <div className="section_input_wrapper">
            <label htmlFor="">Enter experience</label>
            {/* <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter experience"
                    maxLength={2}
                    max={20}
                    value={GeneralDetails_Data?.experience}
                    onInput={(e) => {
                      setGeneralDetails_Data({
                        ...GeneralDetails_Data,
                        experience: e.target.value.replace(/[^0-9]/g, ""),
                      });
                    }}
                  /> */}
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Enter experience"
              value={GeneralDetails_Data?.experience}
              defaultValue={GeneralDetails_Data?.experience}
              onInput={(e) => {
                setGeneralDetails_Data({
                  ...GeneralDetails_Data,
                  experience: e.target.value.replace(
                    /[^a-zA-Z0-9\s-,.'"()/]/g,
                    ""
                  ),
                });
              }}
            ></textarea>
          </div>
        </div>
        <div className="section section3">
          <div className="profile_image">
            <div className="upload_enabler_profile">
              <img
                src={
                  ProfileImage?.name
                    ? URL.createObjectURL(ProfileImage)
                    : ProfileImage == "" || ProfileImage == null
                    ? "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                    : ProfileImage
                }
                alt=""
              />
            </div>

            <input
              type="file"
              name=""
              id="enabler_profile_image_upload"
              accept=".png,.jpg,.jpeg"
              onChange={(e) => {
                setProfileImage(e.target.files[0]);
              }}
            />
            <label htmlFor="enabler_profile_image_upload">Upload profile</label>
          </div>

          <div className="upload_signature">
            <div className="signature_img_warpper">
              {SignatureImage && (
                <img
                  src={
                    SignatureImage?.name
                      ? URL.createObjectURL(SignatureImage)
                      : SignatureImage
                  }
                  alt=""
                />
              )}
            </div>

            <input
              type="file"
              name=""
              id="uploadsignature"
              accept=".png,.jpg,.jpeg"
              onChange={(e) => setSignatureImage(e.target.files[0])}
            />
            <label htmlFor="uploadsignature">Upload signature</label>
          </div>
        </div>
      </div>

      <button className="genereal_details_save_btn" onClick={ADD_Enabler}>
        Next
      </button>
    </div>
  );
};

export default Generealdetails;
