import FileSaver from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { URL } from "../../../DROPDOWNDATA";
import StudentExcel from "../../../images/childupload.xlsx";
import ClassExcel from "../../../images/classupload.xlsx";
import SchoolprincipalExcel from "../../../images/school_upload.xlsx";
import Select from "react-select";
import "./Detailview.css";
import { useSelector } from "react-redux";

const Detailview = ({ setBreadcrumItem }) => {
  const UserRole = useSelector((store) => store.privilege);
  const { id, user_id } = useParams();
  const [SchoolDetails, setSchoolDetails] = useState();
  const [Section_Btn, setSection_Btn] = useState([]);
  // LOADER
  const [IsLoader, setLoader] = useState(false);
  //   ACTIVE BTN
  const [Std_active, setStd_active] = useState(0);
  const [Section_active, setSection_active] = useState(0);
  //   CLASS DETAILS
  const [ClassDetails, setClassDetails] = useState();

  //   ADD CLASS
  const [Showmodal, setShowmodal] = useState(false);
  const [AddclassData, setAddclassData] = useState([
    {
      class: "",
      section: "",
      teacher_name: "",
      teacher_no: "",
      teacher_email: "",
    },
  ]);
  //   EXCEL UPLOADER
  let uploadFile = useRef();
  const [ExcelFile, setExcelFile] = useState();
  const [Which_upload, setWhich_upload] = useState({
    which: true,
    modal: false,
  }); //true means Class upload || false mean Student upload

  const [ShowEnabler_modal, setShowEnabler_modal] = useState(false);
  // TEACHER LIST
  const [TeacherList, setTeacherList] = useState([]);
  const [SelectedTecherFIlter, setSelectedTecherFIlter] = useState([]);

  const [MoveClass, setMoveClass] = useState({
    is_active: false,
    child_list: [],
    to_class: null,
  });
  const [SelectClassModal, setSelectClassModal] = useState(false);
  const [MoveclassList, setMoveclassList] = useState([]);

  const RenderSection = (length, name, id) => {
    let Array = [];

    for (let i = 0; i < length; i++) {
      Array.push({ name: name?.split(",")[i], id: id?.split(",")[i] });
    }
    if (Array[0]?.id) {
      Get_Class_Details(Array[0]?.id, "");
      setSection_active(Array[0]?.id);
    }
    setSection_Btn(Array);
  };
  async function GetSchoolDetails(id) {
    let Data = {
      school_id: Number(id),
    };
    setLoader(true);
    await fetch(URL + `/school_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setSchoolDetails(obj?.body);
        let ClassDuplicate = [];
        for (let i = 0; i < obj?.body?.class_details.length; i++) {
          let SectionList = obj?.body?.class_details[i]?.name?.split(",");
          let SectionIDList = obj?.body?.class_details[i]?.class_id?.split(",");
          for (let j = 0; j < SectionList?.length; j++) {
            ClassDuplicate.push({
              label:
                obj?.body?.class_details[i]?.class_standard +
                " " +
                SectionList[j],
              value: SectionIDList[j],
            });
          }
        }
        setMoveclassList(() => [...ClassDuplicate]);
        setBreadcrumItem([
          { name: "Manage school", link: `/manageschool/${user_id}` },
          {
            name: obj?.body?.school_details?.school_name,
            link: `/schoolDetails/${id}/${user_id}`,
          },
        ]);
        setStd_active(obj?.body?.class_details[0]?.class_standard);
        RenderSection(
          obj?.body?.class_details[0]?.count,
          obj?.body?.class_details[0]?.name,
          obj?.body?.class_details[0]?.class_id
        );
        if (!obj?.body?.class_details[0]?.class_id) {
          setLoader(false);
        }
      })

      .catch((err) => {
        setLoader(false);

        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function GetOtherSchoolDetails(id, teacherID) {
    let Data = {
      school_id: Number(id),
      teacher_id: teacherID,
    };
    setLoader(true);

    await fetch(URL + `/class_evaluation_data_other`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setClassDetails(obj.body);
        setSection_Btn([]);
        setSection_active(0);
        GetTeacherList("", id);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function Get_Class_Details(id, teacherId) {
    let Data = {
      class_id: id,
      teacher_ids: teacherId,
    };
    setLoader(true);
    await fetch(URL + `/child_filter_by_teacher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setClassDetails(obj.body);
        setLoader(false);

        GetTeacherList(id, "");
      })
      .catch((err) => {
        setLoader(false);

        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }

  // GET TEACHER LIST
  const GetTeacherList = async (class_id, school_id) => {
    let Data;
    if (class_id != "") {
      Data = {
        class_id: class_id,
      };
    } else {
      Data = {
        school_id: school_id,
      };
    }

    setLoader(true);
    let Api = "";
    class_id == ""
      ? (Api = "view_teacher_list_other")
      : (Api = "view_teacher_list");

    await fetch(URL + `/${Api}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setTeacherList([]);
        obj?.body?.forEach((item, index) => {
          setTeacherList((prev) => {
            return [
              ...prev,
              {
                label: item?.teacher_name,
                value: item?.teacher_id,
              },
            ];
          });
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  };

  const FilterHandler = async (data) => {
    setSelectedTecherFIlter([...data]);

    let TeacherValue = [];
    for (let i = 0; i < data.length; i++) {
      TeacherValue.push(data[i].value);
    }
    if (Std_active == 99) {
      GetOtherSchoolDetails(id, TeacherValue?.join());
    } else {
      Get_Class_Details(Section_active, TeacherValue?.join());
    }
  };

  // DOWNLOAD SCHOOL REPORT
  const Download_SchoolReport = async () => {
    let Data = {
      school_id: id,
    };
    await fetch(URL + `/download_school_child_report_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) => r.blob())
      .then((obj) => {
        FileSaver.saveAs(
          obj,
          `${SchoolDetails?.school_details?.school_name}_report.xlsx`
        );
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // SELECT CLASS HANDLER
  function ClassHandler(value, index) {
    let DuplicateClassData = AddclassData;
    DuplicateClassData?.map((item, _i) => (item.class = value));

    setAddclassData((prev) => [...DuplicateClassData]);
    // InsertClass();
  }
  // SECTION HANDLER
  function SectionHandler(value, index) {
    let DuplicateClassData = AddclassData;
    DuplicateClassData?.map(
      (item, _i) => (item.section = value?.replace(/[^a-zA-Z \d]/gi, ""))
    );

    setAddclassData((prev) => [...DuplicateClassData]);
  }

  // TEACHER NAME
  function TeachernameHandler(value, index) {
    let DuplicateClassData = AddclassData;
    DuplicateClassData[index].teacher_name = value.replace(/[^a-zA-Z\s-]/g, "");

    setAddclassData((prev) => [...DuplicateClassData]);
  }
  function TeacherEmailHandler(value, index) {
    let DuplicateClassData = AddclassData;
    DuplicateClassData[index].teacher_email = value.replace(/[^a-z0-9@.]/g, "");

    setAddclassData((prev) => [...DuplicateClassData]);
  }

  // TEACHER PH NO
  function TeacherPhnoHandler(value, index) {
    let DuplicateClassData = AddclassData;
    DuplicateClassData[index].teacher_no = value
      .replace(/[^0-9\s]/g, "")
      ?.trim();

    setAddclassData((prev) => [...DuplicateClassData]);
  }
  // ADD CLASS FUNCTION
  function InsertClass() {
    setAddclassData((prev) => [
      ...prev,
      {
        class: AddclassData[0].class,
        section: AddclassData[0].section,
        teacher_name: "",
        teacher_no: "",
      },
    ]);
  }
  // REMOVE CLASS
  function RemoveClassHandler(index) {
    // let DuplicateClassData = AddclassData;
    let DuplicateClassData = AddclassData.filter((item, itindex) => {
      return index != itindex;
    });
    // DuplicateClassData?.filter((item, _i) => {
    //   return _i != 1;
    // });
    // console.log(DuplicateClassData);
    // delete DuplicateClassData[index];
    let newArray = [];

    DuplicateClassData?.forEach((item, ind) => {
      if (item?.class) {
        newArray.push(item);
      }
    });
    setAddclassData((prev) => {
      if (newArray?.length > 0) {
        return [...newArray];
      } else {
        return [
          {
            class: "",
            section: "",
            teacher_name: "",
            teacher_no: "",
            teacher_email: "",
          },
        ];
      }
    });
  }
  async function SubmitClassData() {
    if (AddclassData[0].class == "") {
      toast.error("Kindly fill the class fields");
      toast.clearWaitingQueue();
      return;
    }
    if (AddclassData[0].section == "") {
      toast.error("Kindly fill the section fields");
      toast.clearWaitingQueue();
      return;
    }
    let Proceed = false;
    for (let i = 0; i < AddclassData.length; i++) {
      if (AddclassData[i].teacher_name == "") {
        Proceed = false;
        toast.error("Kindly fill the teacher name");
        toast.clearWaitingQueue();
        return;
      }
      if (AddclassData[i].teacher_no == "") {
        Proceed = false;
        toast.error("Kindly fill the teacher number");
        toast.clearWaitingQueue();
        return;
      }
      if (AddclassData[i].teacher_no?.length != 10) {
        Proceed = false;
        toast.error("mobile number invalid");
        toast.clearWaitingQueue();
        return;
      }
      if (UserRole?.role == "School principal") {
        if (AddclassData[i].teacher_email == "") {
          Proceed = false;
          toast.error("Kindly fill the teacher email");
          toast.clearWaitingQueue();
          return;
        }
      } else {
        Proceed = true;
        continue;
      }

      // else {
      //   if (AddclassData[i].teacher_no == "") {
      //     Proceed = false;
      //     toast.error("Kindly fill the teacher number");
      //     toast.clearWaitingQueue();
      //     return;
      //   } else {
      //     if (AddclassData[i].teacher_no?.length == 10) {
      //       Proceed = true;
      //       continue;
      //     } else {
      //       Proceed = false;
      //       toast.error("mobile number invalid");
      //       toast.clearWaitingQueue();
      //       return;
      //     }
      //   }
      // }
    }

    let Datas = {
      class: AddclassData[0]?.class,
      section: AddclassData[0]?.section,
      school_id: id,
      teacher_details: [],
    };

    AddclassData?.forEach((item, index) => {
      Datas.teacher_details.push({
        teacher_name: item?.teacher_name,
        teacher_mobile: item?.teacher_no,
        teacher_email: item?.teacher_email,
      });
    });
    setLoader(true);
    await fetch(URL + `/add_class_multiple_teachers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Datas),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        if (obj.body?.error) {
          toast.warn(obj.body?.message);
          toast.clearWaitingQueue();
        } else {
          GetSchoolDetails(id);
          setShowmodal(false);
          toast.success(obj.body?.message);
          toast.clearWaitingQueue();
          setAddclassData([
            {
              class: "",
              section: "",
              teacher_name: "",
              teacher_no: "",
            },
          ]);
        }

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const ExcelHandle = (e) => {
    console.log(e.target);
    console.log(e.target.files[0]);
    let FILENAME = e.target.files[0].name?.split(".");
    if (FILENAME[FILENAME?.length - 1] == "xlsx") {
      setExcelFile(e.target.files[0]);
    } else {
      toast.error("Kindly upload ( .xlsx ) format file");
      toast.clearWaitingQueue();
      return;
    }
  };
  const SendExcel = () => {
    if (ExcelFile) {
      setLoader(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("loggedToken")
      );

      var formdata = new FormData();
      formdata.append("school_id", id);
      formdata.append("excel_data", ExcelFile);
      if (!Which_upload?.which) {
        formdata.append("class_id", Section_active);
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        Which_upload?.which
          ? `${URL}/upload_class`
          : `${URL}/upload_student_list`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result.error) {
            setLoader(false);
            toast.error(result?.message);
            toast.clearWaitingQueue();
          } else {
            setWhich_upload({
              which: true,
              modal: false,
            });
            setExcelFile(false);
            GetSchoolDetails(id);
            setLoader(false);
            toast.success(result?.message);
            toast.clearWaitingQueue();
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Failed to upload");
          toast.clearWaitingQueue();
        });
    } else {
      toast.error("Upload Excel file");
      toast.clearWaitingQueue();
    }
  };

  const MoveclassHandler = () => {
    setMoveClass((prev) => ({
      ...prev,
      is_active: !MoveClass?.is_active,
      child_list: [],
      to_class: null,
    }));
  };
  const ChildBoxHandler = (event, child_id) => {
    if (child_id) {
      if (MoveClass.child_list?.includes(child_id)) {
        const FilterArray = MoveClass.child_list?.filter((item, index) => {
          return item !== child_id;
        });
        setMoveClass((prev) => ({ ...prev, child_list: [...FilterArray] }));
      } else {
        let DuplicateArray = [...MoveClass?.child_list];
        DuplicateArray.push(child_id);
        setMoveClass((prev) => ({ ...prev, child_list: [...DuplicateArray] }));
      }
    }
  };
  const SelectClassHandler = () => {
    setSelectClassModal(true);
  };
  const SubmitSelectClassHandler = async () => {
    if (MoveClass?.to_class) {
      setLoader(true);
      let Data = {
        child_ids: MoveClass?.child_list,
        class_id: MoveClass?.to_class,
      };
      await fetch(URL + `/migrate_child`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(Data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          GetSchoolDetails(id);
          setMoveClass((prev) => ({
            ...prev,
            to_class: null,
            child_list: [],
            is_active: false,
          }));
          setSelectClassModal(false);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error("something went wrong");
          toast.clearWaitingQueue();
        });
    } else {
      toast.error("Kindly fill the class fields");
      toast.clearWaitingQueue();
      return;
    }
  };
  useEffect(() => {
    GetSchoolDetails(id);
  }, [id]);

  // useEffect(() => {
  //   InsertClass();
  // }, [AddclassData]);

  return (
    <div className="schooldetail_container">
      <h2 className="schoolname_heading">
        {SchoolDetails?.school_details?.school_name}
      </h2>

      <div className="schooldetails_data_container">
        <div className="school_details_list_container">
          <div className="school-actionbtn">
            {ClassDetails?.total_children_in_class > 0 && (
              <button
                className={`school_classlist_btn`}
                onClick={MoveclassHandler}
              >
                Move class
              </button>
            )}
            <button
              // className="school_classlist_btn"
              className={
                SchoolDetails?.school_details?.school_awarness
                  ? "school_classlist_btn"
                  : "school_classlist_btn"
              }
              onClick={() => {
                setWhich_upload({
                  which: true,
                  modal: true,
                });
              }}
            >
              Upload class list
            </button>
            {ClassDetails?.total_children_in_class > 0 &&
              UserRole?.role != "School principal" && (
                <button
                  // className="school_classlist_btn"
                  className={
                    SchoolDetails?.school_details?.school_awarness
                      ? "school_classlist_btn"
                      : "school_classlist_btn"
                  }
                  onClick={() => {
                    Download_SchoolReport();
                  }}
                >
                  Download report
                </button>
              )}
          </div>
          <div className="school_details_top_btn_container">
            <div className="school_detail_std_btn">
              <div className="std_btn">
                {SchoolDetails?.class_details?.map((item, index) => {
                  return (
                    <button
                      className={
                        Std_active == item?.class_standard ? "active" : ""
                      }
                      key={index}
                      onClick={() => {
                        setStd_active(item?.class_standard);
                        setSelectedTecherFIlter([]);
                        RenderSection(item?.count, item?.name, item?.class_id);
                      }}
                    >
                      {item?.class_standard} Standard
                    </button>
                  );
                })}
              </div>
              <div className="class_list_btn std_btn">
                {UserRole?.role != "School principal" && (
                  <button
                    className={Std_active == 99 ? "active" : ""}
                    onClick={() => {
                      setStd_active(99);
                      setSelectedTecherFIlter([]);
                      GetOtherSchoolDetails(id, "");
                    }}
                  >
                    Others
                  </button>
                )}
                <button
                  onClick={() => {
                    setShowmodal(true);
                  }}
                >
                  <i class="fa-solid fa-circle-plus"></i> Add class
                </button>
              </div>
            </div>
          </div>

          <div className="school_detail_table_sction_container">
            <table className="school_detail_table_container">
              <thead>
                <tr>
                  <th>SPOC</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  {UserRole?.role != "School principal" && <th>Enabler</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{SchoolDetails?.school_details?.school_spoc_name}</td>
                  <td>{SchoolDetails?.school_details?.school_spoc_mobile}</td>
                  <td>{SchoolDetails?.school_details?.school_spoc_emailid}</td>
                  {UserRole?.role != "School principal" && (
                    <td>
                      <button
                        onClick={() => {
                          setShowEnabler_modal(true);
                        }}
                      >
                        {" "}
                        <i class="fa-solid fa-eye"></i>
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            <div className="school_detail_section_list">
              <p>Select section : </p>

              <div className="school_detail_section_list_btn">
                {Section_Btn?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={Section_active == item?.id ? "active" : ""}
                      onClick={() => {
                        setSection_active(item?.id);
                        setSelectedTecherFIlter([]);

                        Get_Class_Details(item?.id, "");
                      }}
                    >
                      Section {item?.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {SchoolDetails?.class_details?.length > 0 ? (
            <div style={{ backgroundColor: "white", padding: "20px 0" }}>
              <div className="school_detail_student_container">
                <div className="teacher_filter_container">
                  <Select
                    defaultMenuIsOpen={false}
                    className="select_options"
                    closeMenuOnSelect={false}
                    options={TeacherList}
                    isMulti
                    onChange={(e) => {
                      let EmptyList = [];
                      e?.forEach((item, index) => {
                        EmptyList.push(item);
                      });
                      // setSelectedTecherFIlter((prev) => [...EmptyList]);
                      FilterHandler(EmptyList);
                    }}
                    value={SelectedTecherFIlter}
                    placeholder="Search teacher"
                  />
                </div>

                <p>No.of students : {ClassDetails?.child_list?.length}</p>

                {UserRole?.role != "School principal" && (
                  <div className="school_detail_student_list_container">
                    <div>
                      <p>Awareness Session</p>
                      <p>
                        {ClassDetails?.class_awarness
                          ? "Completed"
                          : "Incompleted"}
                      </p>
                    </div>
                    <div>
                      <p>Teacher's Evaluation</p>
                      <p>
                        {ClassDetails?.total_children_comp_teacher_evalution}/
                        {ClassDetails?.total_children_in_class}
                      </p>
                    </div>
                    <div>
                      <p>Parent's Evaluation</p>
                      <p>
                        {ClassDetails?.total_children_comp_parent_evalution}/
                        {ClassDetails?.total_children_parent_evaluation}
                      </p>
                    </div>
                    <div>
                      <p>Children's Evaluation</p>
                      <p>
                        {ClassDetails?.total_children_comp_child_evalution}/
                        {ClassDetails?.total_children_child_evaluation}
                      </p>
                    </div>
                  </div>
                )}

                {UserRole?.role != "School principal" && (
                  <button
                    // className="download_report_btn"
                    className={
                      ClassDetails?.child_list?.length > 0
                        ? "download_report_btn"
                        : "download_report_btn disabled"
                    }
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = `https://dlearners.in/standard1a_export_admin.php?school_id=${id}&class_id=${Section_active}`;
                      link.setAttribute(
                        "download",
                        `https://dlearners.in/standard1a_export_admin.php?school_id=${id}&class_id=${Section_active}`
                      );

                      // Append to html link element page
                      document.body.appendChild(link);

                      // Start download
                      link.click();

                      // Clean up and remove the link
                      link.parentNode.removeChild(link);
                    }}
                  >
                    Download report
                  </button>
                )}
              </div>

              <div className="school_detail_child_list">
                <p>Student list</p>
                <button
                  className={
                    ClassDetails?.class_awarness
                      ? "uploadstudent_list_btn"
                      : "uploadstudent_list_btn"
                  }
                  onClick={() => {
                    setWhich_upload({
                      which: false,
                      modal: true,
                    });
                  }}
                >
                  Upload student list
                </button>
              </div>

              <div className="child_list_container">
                {ClassDetails?.child_list?.map((item, index) => {
                  return (
                    <div key={index}>
                      {MoveClass?.is_active && (
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="w-[20px] h-[20px]"
                          checked={MoveClass?.child_list?.includes(
                            item?.child_id
                          )}
                          onChange={(e) => {
                            ChildBoxHandler(e, item?.child_id);
                          }}
                        />
                      )}
                      <p>{item?.child_name}</p>
                      {UserRole?.role != "School principal" && (
                        <NavLink
                          to={`/child_details/${btoa(item?.child_name)}/${btoa(
                            item?.child_id
                          )}`}
                        >
                          View more
                        </NavLink>
                      )}
                    </div>
                  );
                })}
              </div>
              {MoveClass?.child_list?.length > 0 && (
                <button
                  onClick={SelectClassHandler}
                  className="block mx-auto my-2 font-semibold px-3 py-2 rounded-md bg-[#007872] text-white"
                >
                  Select class
                </button>
              )}
            </div>
          ) : (
            <p className="no_class_notify">No classes have been added</p>
          )}
        </div>

        {UserRole?.role != "School principal" && (
          <div className="school_datils_count_container">
            <p>Overall progress</p>

            <div className="first">
              <p>Awareness session</p>
              <h2>
                {SchoolDetails?.school_details?.school_awarness
                  ? "Completed"
                  : "Incompleted"}
              </h2>
            </div>
            <div className="two">
              <p>Teacher's Evaluation</p>
              <h2>
                {SchoolDetails?.teacher_eval_complete_count}/
                {SchoolDetails?.total_teacher_eval_required_count}
              </h2>
            </div>
            <div className="three">
              <p>Parent's Evaluation</p>
              <h2>
                {SchoolDetails?.total_parent_eval_complete_count}/
                {SchoolDetails?.total_parent_eval_required_count}
              </h2>
            </div>
            <div className="four">
              <p>Children's Evaluation</p>
              <h2>
                {SchoolDetails?.total_child_eval_complete_count}/
                {SchoolDetails?.total_child_eval_required_count}
              </h2>
            </div>
          </div>
        )}
      </div>

      {Showmodal && (
        <div className="add_class_modal_container">
          <div className="add_class_modal_block">
            <div className="heading">
              <h2>Add class</h2>
              <button
                onClick={() => {
                  setAddclassData([
                    {
                      class: "",
                      section: "",
                      teacher_name: "",
                      teacher_no: "",
                      teacher_email: "",
                    },
                  ]);
                  setShowmodal(false);
                }}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            <div className="add_class_list_container">
              {AddclassData?.map((item, index) => {
                return (
                  <div className="add_class_input_wrapper" key={index}>
                    <div>
                      {index == 0 && (
                        <label htmlFor="classSelect">Select class</label>
                      )}
                      <select
                        name=""
                        id="classSelect"
                        onChange={(e) => {
                          ClassHandler(e.target.value, index);
                        }}
                        disabled={index == 0 ? false : true}
                        value={AddclassData[0]?.class}
                      >
                        <option value="">Choose class</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>

                    <div>
                      {index == 0 && (
                        <label htmlFor="section">Select section</label>
                      )}
                      <input
                        type="text"
                        id="section"
                        maxLength={2}
                        placeholder="Section"
                        value={AddclassData[0]?.section}
                        onInput={(e) => {
                          SectionHandler(e.target.value, index);
                        }}
                        disabled={index == 0 ? false : true}
                      />
                    </div>
                    <div>
                      {index == 0 && (
                        <label htmlFor="teacherName">Enter teacher name</label>
                      )}
                      <input
                        type="text"
                        id="teacherName"
                        placeholder="Teacher name"
                        value={item?.teacher_name}
                        onInput={(e) => {
                          TeachernameHandler(e.target.value, index);
                        }}
                      />
                    </div>
                    <div>
                      {index == 0 && (
                        <label htmlFor="teacherNumber">Enter teacher no</label>
                      )}
                      <input
                        type="text"
                        maxLength={10}
                        id="teacherNumber"
                        value={item?.teacher_no}
                        onInput={(e) => {
                          TeacherPhnoHandler(e.target.value, index);
                        }}
                        placeholder="Teacher mobile no"
                      />
                    </div>
                    <div>
                      {index == 0 && (
                        <label htmlFor="teacherEmail">
                          Enter teacher email
                        </label>
                      )}
                      <input
                        type="text"
                        id="teacherEmail"
                        placeholder="Teacher Email"
                        value={item?.teacher_email}
                        onInput={(e) => {
                          TeacherEmailHandler(e.target.value, index);
                        }}
                      />
                    </div>
                    <div>
                      <button
                        disabled={index != 0 ? false : true}
                        style={{ opacity: index != 0 ? 1 : 0.3 }}
                        onClick={() => RemoveClassHandler(index)}
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <button className="addClassBtn" onClick={InsertClass}>
              <i class="fa-solid fa-plus"></i> Add class
            </button>

            <button className="add_class_btn" onClick={SubmitClassData}>
              Submit
            </button>
          </div>
        </div>
      )}

      {Which_upload?.modal && (
        <div className="excel_uploader_container">
          <div className="excel_uploader_block">
            <div className="heading">
              <h2>Upload {Which_upload?.which ? "class" : "student"} list</h2>
              <button
                onClick={() => {
                  setWhich_upload({
                    which: true,
                    modal: false,
                  });
                  setExcelFile();
                }}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            <div className="fileupload">
              <input
                type="file"
                name=""
                id="uploadFile"
                ref={uploadFile}
                accept=".xlsx"
                onChange={ExcelHandle}
              />
              <p>{ExcelFile?.name || "Upload file"}</p>
              <button
                onClick={() => {
                  uploadFile.current.click();
                }}
              >
                Browse
              </button>
            </div>

            <div className="upload_btn">
              <a
                // href={
                //   UserRole?.role == "School principal"
                //     ? SchoolprincipalExcel
                //     : Which_upload?.which
                //     ? ClassExcel
                //     : StudentExcel
                // }
                href={
                  Which_upload?.which
                    ? UserRole?.role == "School principal"
                      ? SchoolprincipalExcel
                      : ClassExcel
                    : StudentExcel
                }
                download={
                  Which_upload?.which
                    ? UserRole?.role == "School principal"
                      ? SchoolprincipalExcel
                      : ClassExcel
                    : StudentExcel
                }
              >
                Download format
              </a>
              <button onClick={SendExcel}>Upload</button>
            </div>
          </div>
        </div>
      )}
      {ShowEnabler_modal && (
        <div className="add_school_enabler_view_container">
          <div className="add_school_enabler_view_block">
            <h2>Enabler list</h2>
            <ol>
              {SchoolDetails?.school_details?.enabler_name
                ?.split(",")
                ?.map((item, index) => {
                  return (
                    <li key={index}>
                      {index + 1} . {item}
                    </li>
                  );
                })}
            </ol>
            <button
              onClick={() => {
                setShowEnabler_modal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {SelectClassModal && (
        <div className="move-class-container">
          <div className="move-class-block rounded-md">
            <div className="flex items-center justify-between">
              <h4 className="text-center font-semibold text-lg">
                Select class
              </h4>
              <button
                onClick={() => {
                  setSelectClassModal(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark text-[red] text-3xl"></i>
              </button>
            </div>
            <select
              name=""
              id=""
              className="mx-auto block my-2 border-2 border-[#007872] rounded-md px-3 py-2 text-lg font-semibold"
              value={MoveClass?.to_class}
              onChange={(e) => {
                setMoveClass((prev) => ({ ...prev, to_class: e.target.value }));
              }}
            >
              <option value="" disabled selected>
                Select class
              </option>
              {MoveclassList?.map((item, index) => {
                if (Section_active !== item?.value) {
                  return (
                    <option
                      key={index}
                      value={item?.value}
                      disabled={Section_active == item?.value ? true : false}
                    >
                      {item?.label}
                    </option>
                  );
                }
              })}
            </select>
            <p className="text-sm mt-3">
              * Students can only be moved to existing classes
            </p>
            <button
              onClick={SubmitSelectClassHandler}
              className="block mx-auto my-2 font-semibold px-6 py-2 rounded-md bg-[#007872] text-white"
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {IsLoader && (
        <div className="loader_screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Detailview;
