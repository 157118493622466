import React, { useEffect, useState } from "react";
import "./Screeningprocess.css";
import Topbar from "../childPage/topMenu/TopMenu";
import { useParams } from "react-router-dom";
import { URL as Path } from "../../DROPDOWNDATA";
import "./Screeningprocess.css";
import { toast } from "react-toastify";
import FsLightbox from "fslightbox-react";
import Loader from "../../components/Loader/Loader";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
const Screeningprocess = (props) => {
  const { id, childname } = useParams();
  const [ScreeningProcess, setScreeningProcess] = useState();
  const [Is_loading, setIs_loading] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [ImagesArray, setImagesArray] = useState([]);

  // WORKSHEET DATA
  const [WorksheetData, setWorksheetData] = useState({
    type: "",
    is_modal: "",
  });
  const [WorksheetImage, setWorksheetImage] = useState([]);

  const CHILD_ID = atob(id);
  async function GetScreeningDetails() {
    let item = {
      child_view_id: CHILD_ID,
    };
    setIs_loading(true);
    await fetch(`${Path}/screening_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setIs_loading(false);
        setScreeningProcess(obj.body);
      })
      .catch((err) => {
        setIs_loading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  function MathAssignment() {
    let DuplicateArray = [];
    for (
      let i = 0;
      i < ScreeningProcess?.level_2_response?.math_assessment.length;
      i++
    ) {
      DuplicateArray.push({
        src: ScreeningProcess?.level_2_response?.math_assessment[i],
        description: "",
      });
    }
    setImagesArray(() => [...DuplicateArray]);
    setToggler(!toggler);
  }
  function VMI() {
    let DuplicateArray = [];
    for (let i = 0; i < ScreeningProcess?.level_2_response?.vmi.length; i++) {
      DuplicateArray.push({
        src: ScreeningProcess?.level_2_response?.vmi[i],
        description: "",
      });
    }
    setImagesArray(() => [...DuplicateArray]);
    setToggler(!toggler);
  }
  // WORKSHEET ADD
  const WorksheetImageHandler = (e) => {
    if (WorksheetData?.type == "FreeWriting") {
      if (e.target.files[0]) {
        setWorksheetImage([e.target.files[0]]);
      }
    } else {
      if (e.target.files[0]) {
        let uploads = [];
        for (let i = 0; i < e.target.files.length; i++) {
          uploads.push(e.target.files[i]);
        }
        setWorksheetImage((prev) => [...prev, ...uploads]);
      }
    }
  };
  // DELETE WORkSHEET
  const DeleteWorksheet = (index) => {
    const Duplicate = WorksheetImage?.filter((item, img_index) => {
      return index != img_index;
    });
    setWorksheetImage(() => [...Duplicate]);
  };
  // Worksheet_Submit_Handler
  const Worksheet_Submit_Handler = () => {
    if (WorksheetImage?.length) {
      let Imagename = [];
      for (let i = 0; i < WorksheetImage.length; i++) {
        let extension = WorksheetImage[i]?.name?.split(".")?.pop();
        Imagename.push(
          WorksheetData?.type + "_" + CHILD_ID + "_" + i + "." + extension
        );
      }

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("loggedToken")
      );

      var form = new FormData();
      form.append("assessment_type", WorksheetData?.type);
      form.append("image_name", Imagename?.join());
      form.append("child_id", CHILD_ID);
      WorksheetImage?.forEach((item, index) => {
        if (item?.name) {
          form.append("images", item, Imagename[index]);
        }
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form,
        redirect: "follow",
      };
      setIs_loading(true);
      fetch(Path + "/upload_assessment_asset", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          toast.success("Uploaded successfully");
          toast.clearWaitingQueue();
          GetScreeningDetails();
          setWorksheetData((prev) => ({
            ...prev,
            type: "",
            is_modal: false,
          }));
          setImagesArray([]);
        })
        .catch((error) => {
          setIs_loading(false);
          toast.error("Failed to upload");
          toast.clearWaitingQueue();
        });
    } else {
      toast.warn("Kindly upload worsheet");
      toast.clearWaitingQueue();
    }
  };

  useEffect(() => {
    GetScreeningDetails();
    props.setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        name: atob(childname),
        link: `/child_details/${childname}/${id}`,
      },
    ]);
  }, [id]);

  return (
    <div className="screeing-process-container">
      <Topbar id={id} childname={childname} />

      <div className="screeningprocess_container">
        <h3>Screening process</h3>

        <div className="screening_process_data">
          <div className="screeningprocess_graph_container">
            <div className="screeningprocess_teacher">
              {!ScreeningProcess?.teacher_eval?.academic_observation_is_comp &&
              !ScreeningProcess?.teacher_eval?.behaviour_observation_is_comp ? (
                <div className="notcomplete">
                  <p>Step 1</p>
                  <p>Teacher evaluation</p>
                  <small>
                    {ScreeningProcess?.is_teacher_eval
                      ? "Not completed"
                      : "Not applicable"}{" "}
                  </small>
                </div>
              ) : (
                <div>
                  <p className="card_step">Step 1</p>
                  <div className="screeningprocess_graph_heading">
                    <h4>Teacher evaluation</h4>
                    <p>
                      Completed on :{" "}
                      <span>
                        {ScreeningProcess?.teacher_eval?.completed_on}
                      </span>
                    </p>
                  </div>

                  <div className="teacher_sub_graph_row">
                    <div className="mini_graph">
                      <GraphCircle
                        C1_size={80}
                        C2_size={90}
                        C3_size={70}
                        color="#5875B8"
                        title="Academic observation"
                        total_count={
                          ScreeningProcess?.teacher_eval?.max_academic_points
                        }
                        currentcount={
                          ScreeningProcess?.teacher_eval?.academic_observation
                        }
                        percentage={
                          (ScreeningProcess?.teacher_eval
                            ?.academic_observation /
                            ScreeningProcess?.teacher_eval
                              ?.max_academic_points) *
                          100
                        }
                      />
                      <GraphCircle
                        C1_size={80}
                        C2_size={90}
                        C3_size={70}
                        color="#5875B8"
                        title="Behaviour Observation"
                        total_count={
                          ScreeningProcess?.teacher_eval?.max_behaviour_points
                        }
                        currentcount={
                          ScreeningProcess?.teacher_eval?.behaviour_observation
                        }
                        percentage={
                          (ScreeningProcess?.teacher_eval
                            ?.behaviour_observation /
                            ScreeningProcess?.teacher_eval
                              ?.max_behaviour_points) *
                          100
                        }
                      />
                    </div>
                    <div className="big_graph">
                      <GraphCircle
                        C1_size={150}
                        C2_size={160}
                        C3_size={140}
                        color="#5875B8"
                        title="Consolidated"
                        total_count={
                          ScreeningProcess?.teacher_eval?.max_behaviour_points +
                          ScreeningProcess?.teacher_eval?.max_academic_points
                        }
                        currentcount={
                          ScreeningProcess?.teacher_eval?.consolidated_value
                        }
                        percentage={
                          (ScreeningProcess?.teacher_eval?.consolidated_value /
                            (ScreeningProcess?.teacher_eval
                              ?.max_behaviour_points +
                              ScreeningProcess?.teacher_eval
                                ?.max_academic_points)) *
                          100
                        }
                      />

                      <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.teacher_eval?.download_report}`}
                          target="_blank"
                        >
                          Download report
                        </a>
                      </button>
                      <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.teacher_eval?.view_report}`}
                          target="_blank"
                        >
                        <i className="fa-solid fa-eye"></i>  View report
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="screeningprocess_teacher"
              style={{ width: "500px" }}
            >
              {!ScreeningProcess?.parent_eval?.academic_observation_is_comp &&
              !ScreeningProcess?.parent_eval?.general_observation_is_comp &&
              !ScreeningProcess?.parent_eval?.medical_observation_is_comp ? (
                <div className="notcomplete">
                  <p>Step 2</p>
                  <p>Parent evaluation</p>
                  <small>
                    {ScreeningProcess?.child_parent_eval_required == "N"
                      ? "Not required"
                      : ScreeningProcess?.child_parent_eval_required == "Y"
                      ? "required"
                      : ""}
                  </small>
                </div>
              ) : (
                <div>
                  <p className="card_step">Step 2</p>
                  <div className="screeningprocess_graph_heading">
                    <h4>Parent evaluation</h4>
                    <p>
                      Completed on :{" "}
                      <span>{ScreeningProcess?.parent_eval?.completed_on}</span>
                    </p>
                  </div>

                  <div className="teacher_sub_graph_row">
                    <div
                      className="mini_graph"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <GraphCircle
                        C1_size={80}
                        C2_size={90}
                        C3_size={70}
                        color="#FFDAC1"
                        title="Academic observation"
                        total_count={8}
                        currentcount={
                          ScreeningProcess?.parent_eval?.academic_observation
                        }
                        percentage={
                          (ScreeningProcess?.parent_eval?.academic_observation /
                            8) *
                          100
                        }
                      />
                      <GraphCircle
                        C1_size={80}
                        C2_size={90}
                        C3_size={70}
                        color="#FFDAC1"
                        title="General observation"
                        total_count={15}
                        currentcount={
                          ScreeningProcess?.parent_eval?.general_observation
                        }
                        percentage={
                          (ScreeningProcess?.parent_eval?.general_observation /
                            15) *
                          100
                        }
                      />
                      <GraphCircle
                        C1_size={80}
                        C2_size={90}
                        C3_size={70}
                        color="#FFDAC1"
                        title="Medical observation"
                        total_count={8}
                        currentcount={
                          ScreeningProcess?.parent_eval?.medical_observation
                        }
                        percentage={
                          (ScreeningProcess?.parent_eval?.medical_observation /
                            8) *
                          100
                        }
                      />
                    </div>
                    <div className="big_graph">
                      <GraphCircle
                        C1_size={150}
                        C2_size={160}
                        C3_size={140}
                        color="#FFDAC1"
                        title="Consolidated"
                        total_count={31}
                        currentcount={
                          ScreeningProcess?.parent_eval?.consolidated_value
                        }
                        percentage={
                          (ScreeningProcess?.parent_eval?.consolidated_value /
                            31) *
                          100
                        }
                      />

                      <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.parent_eval?.download_report}`}
                          target="_blank"
                        >
                          Download report
                        </a>
                      </button>
                      <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.parent_eval?.view_report}`}
                          target="_blank"
                        >
                        <i className="fa-solid fa-eye"></i>  View report
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="screeningprocess_table">
            {!ScreeningProcess?.level_1_completed ? (
              <div className="notcomplete">
                <p>Step 3</p>
                <p>Child screening app based</p>
                <small>
                  {ScreeningProcess?.child_level1_eval_required == "N"
                    ? "Not required"
                    : ScreeningProcess?.child_level1_eval_required == "Y"
                    ? "required"
                    : ""}
                </small>
              </div>
            ) : (
              <div>
                <p className="card_step">Step 3</p>
                <div className="screeningprocess_graph_heading">
                  <h4>Child screening app based</h4>
                  <p>
                    Completed on :{" "}
                    <span>{ScreeningProcess?.level_1_completed_on}</span>
                  </p>
                </div>
                <table className="screening_report_table_data screening_process_tabledata">
                  <thead>
                    <tr>
                      <th>Concept</th>
                      <th>Evaluation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ScreeningProcess?.level_1_response?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.concept}</td>
                          <td>{item?.evaluation}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <button
                  className="download_report_btn"
                  style={{ marginTop: "20px" }}
                >
                  <a
                    href={`${ScreeningProcess?.level_1_report}`}
                    target="_blank"
                  >
                    Download report
                  </a>
                </button>
                <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.level_1_report_view}`}
                          target="_blank"
                        >
                        <i className="fa-solid fa-eye"></i>  View report
                        </a>
                      </button>
              </div>
            )}
          </div>
          <div className="screeningprocess_child2">
            {ScreeningProcess?.level_2_response?.completed_on == "" ||
            ScreeningProcess?.level_2_response?.completed_on == null ? (
              <div className="notcomplete">
                <p>Step 4</p>
                <p>Child screening one on one</p>
                <small>Not completed</small>
              </div>
            ) : (
              <div>
                <p className="card_step">Step 4</p>
                <div className="screeningprocess_graph_heading">
                  <h4>Child screening one on one</h4>
                  <p>
                    Completed on :{" "}
                    <span>
                      {ScreeningProcess?.level_2_response?.completed_on}
                    </span>
                  </p>
                </div>
                <div className="child_screening_completetion">
                  <p>
                    Enabler :{" "}
                    <span>
                      {ScreeningProcess?.level_2_response?.enabler_name}
                    </span>
                  </p>
                  <p>
                    Zoom link :{" "}
                    <span>
                      <a
                        href={`${ScreeningProcess?.level_2_response?.meeting_link}`}
                        target="_blank"
                      >
                        Click to join
                      </a>
                    </span>
                  </p>
                </div>
                <h4>Post call reports</h4>

                <div className="post_call_report">
                  <p>Raw report </p>
                  <p></p>
                  {ScreeningProcess?.level_2_response?.raw_report != "" ||
                  ScreeningProcess?.level_2_response?.raw_report != null ? (
                    <button>
                      <a
                        href={`${ScreeningProcess?.level_2_response?.raw_report}`}
                        target="_blank"
                      >
                        View
                      </a>
                    </button>
                  ) : (
                    <p>Not complete</p>
                  )}
                </div>
                <div className="post_call_report">
                  <p>Free writing</p>
                  {ScreeningProcess?.level_2_response?.free_writing_taken ? (
                    <button
                      onClick={() => {
                        setWorksheetImage([]);
                        setWorksheetData((prev) => ({
                          ...prev,
                          is_modal: true,
                          type: "FreeWriting",
                        }));
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    ""
                  )}
                  {ScreeningProcess?.level_2_response?.free_writing_taken ? (
                    <button
                      onClick={() => {
                        setImagesArray(() => [
                          {
                            src: ScreeningProcess?.level_2_response
                              ?.free_writing,
                            description: "",
                          },
                        ]);
                        setToggler(!toggler);
                      }}
                    >
                      {ScreeningProcess?.level_2_response?.free_writing
                        ? "View"
                        : ""}
                    </button>
                  ) : (
                    <p>Not complete</p>
                  )}
                </div>
                <div className="post_call_report">
                  <p>Math assignment </p>
                  {ScreeningProcess?.level_2_response?.math_assessment_taken ? (
                    <button
                      onClick={() => {
                        setWorksheetImage([]);
                        setWorksheetData((prev) => ({
                          ...prev,
                          is_modal: true,
                          type: "Math",
                        }));
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    ""
                  )}
                  {ScreeningProcess?.level_2_response?.math_assessment_taken ? (
                    <button
                      onClick={() => {
                        MathAssignment();
                      }}
                    >
                      {ScreeningProcess?.level_2_response?.math_assessment
                        ?.length
                        ? "View"
                        : ""}
                    </button>
                  ) : (
                    <p>Not complete</p>
                  )}
                </div>
                <div className="post_call_report">
                  <p>Visual motor integration</p>
                  {ScreeningProcess?.level_2_response?.vmi_writing_taken ? (
                    <button
                      onClick={() => {
                        setWorksheetImage([]);
                        setWorksheetData((prev) => ({
                          ...prev,
                          is_modal: true,
                          type: "VMI",
                        }));
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    ""
                  )}
                  {ScreeningProcess?.level_2_response?.vmi_writing_taken ? (
                    <button
                      onClick={() => {
                        VMI();
                      }}
                    >
                      {ScreeningProcess?.level_2_response?.vmi?.length
                        ? "View"
                        : ""}
                    </button>
                  ) : (
                    <p>Not complete</p>
                  )}
                </div>

                <button
                  className="download_report_btn"
                  style={{ marginTop: "20px" }}
                >
                  <a
                    href={`${ScreeningProcess?.level_2_report}`}
                    target="_blank"
                  >
                    Download report
                  </a>
                </button>
                <button className="download_report_btn">
                        <a
                          href={`${ScreeningProcess?.level_2_report_view}`}
                          target="_blank"
                        >
                        <i className="fa-solid fa-eye"></i>  View report
                        </a>
                      </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <FsLightbox toggler={toggler} sources={ImagesArray} /> */}
      {/* <Lightbox
        open={toggler}
        close={() => setToggler(false)}
        slides={ImagesArray}
        plugins={[Counter]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
      /> */}
      <ReactImageCarouselViewer
        open={toggler}
        images={ImagesArray}
        startIndex={0}
        onClose={() => setToggler(false)}
        disableScroll={true}
      />
      {WorksheetData?.is_modal && (
        <div className="worksheet-upload-container">
          <div className="worksheet-upload-block">
            <div className="worksheet-upload-header">
              <p>Upload</p>
              <button
                onClick={() => {
                  setWorksheetData((prev) => ({
                    ...prev,
                    type: "",
                    is_modal: false,
                  }));
                  setWorksheetImage([]);
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="worksheet-upload-body">
              <div className="worksheet-upload-body-img-wrapper">
                {WorksheetImage?.map((item, index) => {
                  if (item) {
                    return (
                      <div
                        className="worksheet-upload-body-img-cover"
                        key={index}
                      >
                        <img src={URL.createObjectURL(item)} alt="" />
                        <div className="worksheet-upload-body-img-delete">
                          <button onClick={() => DeleteWorksheet(index)}>
                            <i class="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    );
                  }
                })}
                {!WorksheetImage?.length && <p>No image selected</p>}
              </div>
              <div className="worksheet-upload-body-img-upload">
                <input
                  type="file"
                  name=""
                  id="worksheetupload"
                  onChange={WorksheetImageHandler}
                  accept=".png,.PNG,.JPG,.jpg,.JPEG,.jpeg"
                  multiple={WorksheetData?.type != "FreeWriting" ? true : false}
                />
                <label htmlFor="worksheetupload">
                  <i class="fa-solid fa-upload"></i> Upload
                </label>
              </div>
            </div>
            <div className="worksheet-upload-footer">
              <button onClick={Worksheet_Submit_Handler}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {Is_loading && <Loader />}
    </div>
  );
};

const GraphCircle = ({
  C1_size,
  C2_size,
  C3_size,
  color,
  title,
  total_count = "",
  currentcount = "",
  percentage = "0",
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "10px 10px 0 0 ",
      }}
    >
      <div
        className="graph_circle-1"
        style={{ width: `${C1_size}px`, height: `${C1_size}px` }}
      >
        <div
          className="graph_circle-2"
          style={{
            width: `${C2_size}px`,
            height: `${C2_size}px`,
            background: `conic-gradient(${color} ${percentage}%, transparent 0%)`,
          }}
        >
          <div
            className="graph_circle-3"
            style={{ width: `${C3_size}px`, height: `${C3_size}px` }}
          >
            {currentcount}/{total_count}
          </div>
        </div>
      </div>
      <p className="graph_title">{title}</p>
    </div>
  );
};
export default Screeningprocess;
