import React from "react";
import { useNavigate } from "react-router-dom";

const Count = ({ title, count, path }) => {
  const Navigate = useNavigate();
  return (
    <div className="flex p-2 py-4 bg-[#FFFFFF] rounded-xl items-center m-2 border-2 border-[#007872] w-[300px] items-center justify-between">
      <div className="mr-[0px]">
        <p className="font-bold text-[18px]">{title}</p>
        {path != "" ? (
          <button
            onClick={() => Navigate(`/${path}`)}
            className="font-medium text-[16px] my-1"
          >
            View more <i class="fa-regular fa-eye ml-1"></i>
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="font-bold text-[38px] px-5">{count}</div>
    </div>
  );
};

export default Count;
